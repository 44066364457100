<template>
  <div>
      <slot name="ButtonAction" >
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click="showModal = !showModal"
                variant="outline-primary"
            >
                <feather-icon icon="PlusIcon" />
                Nuevo
            </b-button>
        </slot>
        <b-modal
            id="modal-categories"
            v-model="showModal"
            :ok-title="modeEdit ? 'Actualizar' : 'Guardar'"
            cancel-title="Cancelar"
            centered
            :title="modeEdit ? 'Editar categoría' : 'Nueva categoría'"
            @ok="saveMetho"
        >
        <b-overlay
            :show="loading"
            rounded
            responsive
            variant="transparent"
            opacity="0.33"
            blur="2px"
        >
            <b-form @submit.stop.prevent="saveMetho">
                <b-form-group>
                <label for="nombre">Nombre:</label>
                <b-form-input
                    id="nombre"
                    placeholder="Nombre"
                    v-model="formData.name"
                />
                </b-form-group>
                <b-form-group>
                <label for="Descripcion">Descripción</label>
                <b-form-input
                    id="Descripcion"
                    placeholder="Descripción"
                    v-model="formData.description"
                />
                </b-form-group>

                <b-form-group label="Colegio">
                <v-select
                    v-model="formData.school_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="schoolOptions"
                    label="name"
                    :reduce="(item) => item.id"/>
                </b-form-group>

            <b-media class="mb-2">
                <template #aside>
                <b-avatar
                    ref="previewEl"
                    :src="image"
                    :variant="`light-success`"
                    size="250px"
                    rounded
                />
                </template>
                <div class="d-flex flex-wrap">
                <b-button variant="primary" @click="$refs.refInputEl.click()">
                    <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="uploadImage">
                    <feather-icon
                    icon="UploadIcon"
                    class="d-inline mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Subir</span>
                </b-button>
                </div>
            </b-media>
            </b-form>
        </b-overlay>
        </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BOverlay, BAvatar, BMedia
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

export default {
    components: {
        BButton,
        BModal,
        VBModal,
        BForm,
        BFormInput,
        BFormGroup,
        BOverlay,
        vSelect,
        BAvatar, BMedia
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    async mounted() {
        this.schoolOptions = await this.$store.dispatch('schools/index', {schools: true})
    },
    data() {
        return {
            image: null,
            schoolOptions: [],
            showModal: false,
            formData: {
                image: null,
            },
            loading: false,
            modeEdit: false,
            page: 1,
        }
    },
    watch:{
        showModal(val){
            if(!val){
                this.formData = {
                    name: null,
                    description: null,
                    image: null,
                }
                this.modeEdit = false
            }
        }
    },
    methods: {
        uploadImage() {
            const { inputImageRenderer } = useInputImageRenderer(this.$refs.refInputEl, this.getImage)

            inputImageRenderer()
        },
        getImage(base64) {
            this.image = base64
            this.formData.image = base64
        },
        show() {
            this.showModal = true
        },
        hide() {
            this.showModal = false
        },
        editMetho(data, page) {
            this.page = page
            this.image = data.image
            this.modeEdit = true
            this.formData = {
                id: data.id,
                name: data.name,
                description: data.description,
                school_id: data.school_id,
            }
            this.showModal = true
        },
        async saveMetho(bvModalEvt) {
            try {
                this.loading = true
                bvModalEvt.preventDefault()
                
                if(this.modeEdit){
                    if(this.image) {
                        await this.$store.dispatch('products/updateCategory', this.formData)
                        .then(() => {
                            this.image = null
                        })
                    } else {
                        await this.$store.dispatch('products/updateCategory', {
                            id: this.formData.id,
                            name: this.formData.name,
                            description: this.formData.description,
                            school_id: this.formData.school_id
                        }).then(() => {
                            this.image = null
                        })
                    }
                }else{
                    await this.$store.dispatch('products/storeCategory', this.formData)
                    .then(() => {
                        this.image = null
                    })
                }
                this.$emit('refeshData')
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Éxito',
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                        text: `Categoría ${this.modeEdit ? 'actualizada' : 'creada'} correctamente`,	
                    },
                })
                this.showModal = false
            } catch (error) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: 'Error al crear la categoría',
                    },
                })
            }finally{
                this.loading = false
            }
        },
    },

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>