<template>
  <div>
    <b-card>
      <b-row v-if="isAdmin || isCashier">
        <b-col class="d-flex justify-content-end mb-2">
          <CreateEdit ref="createEdit" @refeshData="getItems(page)" />
        </b-col>
      </b-row>

      <b-overlay
        :show="loading"
        rounded
        responsive
        variant="transparent"
        opacity="0.33"
        blur="2px"
        class="table-responsive"
      >
        <b-table
          hover
          :items="items.data"
          primary-key="id"
          :fields="fields"
          show-empty
          empty-text="No hay categorías registradas"
        >
          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDateTimeHumane }}
          </template>

          <template #cell(image)="data">
            <img
              :src="data.item.image"
              width="100"
              height="100"
              @error="handleImageError($event)"
              alt="Imagen"
            />
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="editMetho(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="isAdmin || isCashier"
                @click="deleteMetho(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="items.current_page"
              :total-rows="items.total"
              :per-page="items.per_page"
              first-number
              @change="getItems"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BLink,
  BPagination,
} from "bootstrap-vue";

// NewCategory
import CreateEdit from "./createEdit";
import store from "@/store";
import { formatDateTimeHumane } from "@core/utils/filter.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BAvatar,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BLink,
    BPagination,
    CreateEdit,
  },
  filters: {
    formatDateTimeHumane,
  },
  data() {
    return {
      loading: false,
      imgError: process.env.VUE_APP_AWS_BUCKET + "/icons/PNG/Imagen_no_disponible.png",
      items: {},
      page: 1,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    fields() {
      let data = [
        { key: "name", label: "Nombre", sortable: true },
        { key: "image", label: "Image", sortable: true },
        { key: "description", label: "Descripción", sortable: true },
        { key: "school.name", label: "Colegio", sortable: true },
        { key: "created_at", label: "Creado", sortable: true },
      ];

      if (this.isAdmin || this.isCashier) {
        data.push({ key: "actions", label: "Acciones" });
      }

      return data;
    },
  },
  methods: {
    handleImageError(event) {
      event.target.src = this.imgError;
    },
    async getItems(page = 1) {
      this.page = page;
      try {
        this.loading = true;
        this.items = await store.dispatch("products/indexCategory", {
          page: page,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteMetho({ id, name, school }) {
      try {
        const res = await this.$bvModal.msgBoxConfirm(
          `Estas seguro de borrar la categoría ${name}? del colegio ${school.name}`,
          {
            title: "Por favor confirmar",
            size: "sm",
            okVariant: "primary",
            okTitle: "Si",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (res) {
          await store.dispatch("products/deleteCategory", id);
          this.getItems(this.page);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Categoría eliminada correctamente",
            },
          });
        } else {
          console.log("Cancelado");
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "No se pudo eliminar la categoría, puede que este siendo usada.",
          },
        });
      }
    },
    editMetho(item) {
      const itemEdit = JSON.parse(JSON.stringify(item));
      this.$refs.createEdit.editMetho(itemEdit, this.page);
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>
